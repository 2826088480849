/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
const LIMIT_PER_PAGE = 10;

async function requestsData(token, page = 1, limit = LIMIT_PER_PAGE, status = '', payload_id = '', company_id = '', location_id = '') {
  return fetch(process.env.REACT_APP_API_URL + '/app/requests/?' + 
    new URLSearchParams({
      'page': page,
      'limit': limit,
      'status': status,
      'payload_id': payload_id,
      'company_id': company_id,
      'location_id': location_id,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function renderStatus(status) {
  switch(status) {
    case 'ERROR':
      return <span className='uk-text-danger'>ERROR</span>;
    case 'PENDING':
      return <span className='uk-text-warning'>PENDING</span>;         
    default:
      return <span className='uk-text-success'>{status}</span>; 
  }
}

export default function Requests(props) {
  const [requests, setRequests] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(LIMIT_PER_PAGE);
  const [status, setStatus] = useState('');
  const [error, setError] = useState(false);
  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await requestsData(props.token, page, limit, status, props.payload , props.company, props.location);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setRequests(data.requests.data);
        setPage(data.requests.meta.current_page)
        setLastPage(data.requests.meta.last_page)
        setTotal(data.requests.meta.total)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, page, limit, status, props.payload, props.company, props.location]);

  return(
    <div id="requests" className="uk-card uk-card-default">
      <div className="uk-card-header">
        <h3 className="uk-card-title">GatherUp posts ({total})</h3>
      </div>

      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>GatherUp posts error!</p></div>
      : null}

      {requests ? 
        <div className="uk-card-body">
          <div uk-filter="target: .js-filter; animation: fade;">
            <ul className="uk-subnav uk-subnav-pill">
                <li className="uk-active" uk-filter-control=""><a href="#requests" onClick={() => setStatus('')}>ALL</a></li>
                <li uk-filter-control="[data-status='PENDING']"><a href="#requests" onClick={() => setStatus('PENDING')}>PENDING</a></li>
                <li uk-filter-control="[data-status='DONE']"><a href="#requests" onClick={() => setStatus('DONE')}>DONE</a></li>
                <li uk-filter-control="[data-status='ERROR']"><a href="#requests" onClick={() => setStatus('ERROR')}>ERROR</a></li>
                <li uk-filter-control="[data-status='EXIST']"><a href="#requests" onClick={() => setStatus('EXIST')}>EXIST</a></li>
            </ul>
          
          {total ?  
            <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="uk-table-expand">Status</th>
                        <th className="uk-table-expand">Sent to GatherUp</th>
                        {!props.payload ? <th className="uk-table-expand"> OMS Payload</th> : null}
                        {!props.location ? <th className="uk-table-expand">Location</th> : null}
                        <th></th>
                    </tr>
                </thead>
                <tbody className="js-filter">
                  {(requests).map((request) =>
                      <tr key={request.id} data-status={request.status}>
                        <td><a href={'/payload/' + request.payload_id + '/request/' + request.id}>{request.id}</a></td>
                        <td>{renderStatus(request.status)}</td>
                        <td>{request.sent_at ? new Date(request.sent_at).toLocaleString('en-US') : 'N/A'}</td>

                        {!props.payload ? <td><a href={'/payload/' + request.payload_id + '/'}>{request.payload_id}</a></td> : null}
                        {!props.location ? <td><a href={'/company/' + request.company_id + '/client/' + request.client_id + '/location/' + request.location_id + '/'}>{request.location_name}</a></td> : null}

                        <td><a href={'/payload/' + request.payload_id + '/request/' + request.id}><span uk-icon="pencil"></span></a></td>
                      </tr>
                  )}
                </tbody>
            </table>
          : <p>No results</p>}
          </div>
          <Pagination page={page} lastPage={lastPage} total={total} limit={limit} setPage={setPage}/>
        </div>
      : null}
    </div>
  );
}
